import React from "react";
import "./Footer.css";
import InTouch from "./InTouch";
import logo from "../../assets/logo.png";
import {
  BsInstagram,
  BsTelephoneFill,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";
import { FaFacebookF } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { Link, NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-[#8A2320] relative footer-margin text-white pt-[8rem] px-[6rem] pb-[4rem] mt-[20rem] w-[100%]">
      <InTouch />
      <div className="grid md:grid-cols-3 gap-[2rem] mb-[2rem]">
        <div className="flex flex-col gap-[1rem] align-items">
          <img className="w-[100px]" src={logo} alt="" />
          <div className="align-items">
            An upcoming leader in manufacturing of PP/HDPE, BOPP bags used for
            cement, rice, flour, fertilizers, and other agro products packaging.
            For bulk and small packaging.
          </div>
          <div className="flex gap-[1rem] text-[#8A2320]">
            <div className="p-2 bg-white rounded-full">
              <a
                href="https://instagram.com/packaging_grweaver"
                target="_blank"
                rel="noopener noreferrer"
              >
                <BsInstagram />
              </a>
            </div>
          </div>
        </div>
        <div className="text-align">
          <div className="font-bold mb-6">Quick Links</div>
          <div className="grid grid-cols-2 gap-[1rem]">
            <div className="flex flex-col gap-[1.5rem]">
              <div className="">
                <Link to="/">Home</Link>
              </div>
              <div className="">
                <Link to="/about">About</Link>
              </div>
              <div className="">
                <Link to="/products">Products</Link>
              </div>
            </div>
            <div className="flex flex-col gap-[1.5rem]">
              <div className="">
                <Link to="/career">Career</Link>
              </div>
              <div className="">
                <Link to="/enquiry">Enquiry</Link>
              </div>
              <div className="">
                <Link to="/contact">Contact</Link>
              </div>
            </div>
          </div>
        </div>
        <div className="text-align">
          <div className="font-bold mb-6">Contact</div>
          <div className="flex flex-col gap-[1rem] align-items">
            <div className="">
              <span className='font-bold'>Factory Address :</span> 14-18-19-20-21, Industrial Area, Banmore Distt.
              Morena- 476 444 (M.P) India, <br />
              <span className='font-bold'>Corporate Office : </span>'IMPERIA' G R Group, Naya Bazar, Lashkar, Gwalior, MP, India 474009
            </div>
            <div className="flex gap-[1rem] items-center">
              <BsTelephoneFill />
              <span>
                <a href="tel:[75123 20718]">+91 75123 20718</a> &nbsp;
                <a href="tel:[+91 97525 31000]">+91 97525 31000</a>
              </span>
            </div>
            <div className="flex gap-[1rem] items-center">
              <IoIosMail />
              <a
                href="https://mail.google.com/mail/u/0/#inbox?compose=DmwnWsdLSQmjCPZwblklBtCGMlVwBZGSbVLBnSXSxNGNzCnPPrlzPMmvlGPhxrSWlGCksjHDzFjB"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>grgroup.sales@gmail.com</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Footer;

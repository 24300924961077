import React from 'react'
import { HiArrowLongRight } from 'react-icons/hi2'
import { Link } from 'react-router-dom'

const InTouch = () => {
  return (
    <div className='absolute top-[-10rem] flex justify-center left-[0rem] w-[100%]'>
      <div className='sm:w-[80%] w-[90%] rounded-[1rem] mx-auto py-[4rem] bg-[#F7EAD7] flex flex-col justify-center items-center gap-[1rem]'>
        <div className='md:text-3xl text-2xl font-extrabold text-black'>Get In Touch</div>
        <div className='border-[#8A2320] border-2 p-2 rounded-[5rem]'>
          <button className='gap-2 font-semibold bg-[#8A2320] px-4 py-2 rounded-3xl'><span><Link to="/contact">Contact Us</Link></span><HiArrowLongRight style={{display: "inline-block", alignItems: "center !important"}} className='text-[1.5rem]' /></button>
        </div>
      </div>
    </div>
  )
}

export default InTouch

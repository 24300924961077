import React, { useEffect } from 'react'
import Top from '../Components/Top/Top'
import bg from '../assets/enquiry.png';
import EnquiryForm from '../Components/enquiry/EnquiryForm';

function Enquiry() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
        <Top title={'Enquiry'} bg={bg} link={'enquiry'} />
        <EnquiryForm />
    </div>
  )
}

export default Enquiry
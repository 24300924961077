import React from "react";

const BriefElement = ({ number, name }) => {
  return (
    <div className="flex gap-[1rem] mb-[14px]">
        <div className="text-2xl font-bold text-[#8A2320]">{number}</div>
        <div className="text-lg font-semibold">{name}</div>
    </div>
  );
};

export default BriefElement;

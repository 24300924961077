import React from 'react';
import brand1 from '../../assets/brand1.svg';
import brand2 from '../../assets/brand2.svg';
import brand3 from '../../assets/brand3.svg';

const BrandBrief = () => {
    return (
        <>
        <div className=' py-[5rem] md:px-[7rem] px-[4rem] grid md:gap-[1rem] gap-[3rem] md:grid-cols-3 grid-cols-1 text-white'>
            {/* <div className='flex flex-col gap-[1rem] justify-center items-center'>
                <img src={brand1} className='w-[2rem]' alt='' />
                <div className='text-3xl font-bold'>
                    14000+
                </div>
                <div className=''>
                    Brands Including Variants
                </div>
            </div>
            <div className='flex flex-col gap-[1rem] justify-center items-center'>
                <img src={brand2} className='w-[2rem]' alt='' />
                <div className='text-3xl font-bold'>
                    28+
                </div>
                <div className=''>
                    Years Of Experience
                </div>
            </div>
            <div className='flex flex-col gap-[1rem] justify-center items-center'>
                <img src={brand3} className='w-[2rem]' alt='' />
                <div className='text-3xl font-bold'>
                    65+
                </div>
                <div className=''>
                    Countries we export to
                </div>
            </div> */}
        </div>
        </>
    )
}

export default BrandBrief

import Navbar from "./Components/Navbar/Navbar";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./Pages/Home";
import Products from "./Pages/Products";
import Footer from "./Components/Footer/Footer";
import Career from "./Pages/Career";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import "./App.css";
import Enquiry from "./Pages/Enquiry";
import { useEffect, useState } from "react";

export default function App() {
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  return (
    <div className="App">
      {isLoading ? (
        <div
          className="loader-logo"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%",
          }}
        ></div>
      ) : (
        <Router>
          <Navbar />
          <Routes>
            <Route path={`/`} element={<Home />} />
            <Route path={`/about`} element={<About />} />
            <Route path={`/products`} element={<Products />} />
            <Route path={`/career`} element={<Career />} />
            <Route path={`/enquiry`} element={<Enquiry />} />
            <Route path={`/contact`} element={<Contact />} />
          </Routes>
          <Footer />
        </Router>
      )}
    </div>
  );
}

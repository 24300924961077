import React, { useState } from "react";
// import L from 'leaflet';
import "leaflet/dist/leaflet.css";
// import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import { SlLocationPin, SlPhone } from "react-icons/sl";
import { AiOutlineMail } from "react-icons/ai";
import "./Contact.css";
// import loc from '../../assets/location.png'

const ContactMid = () => {
  const baseUrl = "https://gr8-back.onrender.com";

  const contactData = {
    name: "",
    description: "",
    email: "",
    contact: "",
  };

  const [data, setData] = useState(contactData);

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);

    if (
      data?.name.trim() === "" ||
      data?.description.trim() === "" ||
      data?.email.trim() === ""
    ) {
      window.alert("Please fill all the fields");
    } else if (data?.contact.length !== 10) {
      window.alert("Please enter a valid phone number");
    } else {
      console.log(data);
      const response = await fetch(`${baseUrl}/lead/create`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
      });
      if (response.status === 401) {
        console.log("error");
      }
      const result = await response.json();
      window.alert(result?.message);
      setData(contactData);
    }
  };

  // useEffect(() => {
  //     const map = L.map(mapContainer.current, {
  //         center: [26.1941707, 78.1551375],
  //         zoom: 13,
  //     });

  //     const tileLayer = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
  //         attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
  //         maxZoom: 19,
  //     }).addTo(map);

  //     const myIcon = L.icon({
  //         iconUrl: loc,
  //         iconSize: [30, 30],
  //         iconAnchor: [15, 30],
  //         popupAnchor: [0, -30],
  //         shadowUrl: iconShadow,
  //         shadowSize: [30, 30],
  //         shadowAnchor: [15, 30],
  //     });

  //     const marker = L.marker([26.1941707, 78.1551375], { icon: myIcon }).addTo(map);

  //     mapContainer.current.style.width = 'auto';
  //     mapContainer.current.style.height = '200px';

  //     return () => {
  //         map.remove();
  //     };
  // }, []);

  return (
    <div
      className="my-10 mt-20 rounded-lg max-w-[850px] mx-auto p-12 w-[90%]"
      style={{ boxShadow: "2px 10px 28px rgba(138, 35, 32, 0.1)" }}
    >
      <h2
        className="text-center font-semibold text-2xl text-[#8A2320]"
        style={{ paddingBottom: "50px", fontSize: "24px", fontWeight: 600 }}
      >
        Get In Touch
      </h2>
      <div className="text-xl font-semibold mb-[1.5rem]">
        Leave us a message
      </div>
      <div
        className="grid grid-cols-1 sm:grid-cols-2 gap-[2rem]"
        style={{ marginBottom: "80px" }}
      >
        <div className="flex flex-col">
          <div className="input-group" style={{ marginBottom: "24px" }}>
            <input
              required=""
              type="text"
              name={"name"}
              autoComplete="off"
              className={`input border-2 rounded-md p-[0.5rem] px-2 w-[100%] ${
                data?.name.trim() !== "" && "active-input"
              }`}
              value={data?.name}
              onChange={handleChange}
            />
            <label className="user-label">Name</label>
          </div>
          <div className="input-group" style={{ marginBottom: "24px" }}>
            <input
              required=""
              type="email"
              name="email"
              autoComplete="off"
              className={`input border-2 rounded-md p-[0.5rem] px-2 w-[100%] ${
                data?.email.trim() !== "" && "active-input"
              }`}
              value={data?.email}
              onChange={handleChange}
            />
            <label className="user-label">Email Address</label>
          </div>
          <div className="input-group" style={{ marginBottom: "24px" }}>
            <input
              required=""
              type="text"
              name="contact"
              autoComplete="off"
              className={`input border-2 rounded-md p-[0.5rem] px-2 w-[100%] ${
                data?.contact !== "" && "active-input"
              }`}
              value={data?.contact}
              onChange={handleChange}
            />
            <label className="user-label">Phone No</label>
          </div>
          <div className="input-group" style={{ marginBottom: "40px" }}>
            <textarea
              required=""
              type="text"
              name="description"
              style={{ height: "137px" }}
              autoComplete="off"
              className={`input border-2 rounded-md p-[0.5rem] px-2 w-[100%] ${
                data?.description.trim() !== "" && "active-input"
              }`}
              value={data?.description}
              onChange={handleChange}
            />
            <label className="user-label">Description</label>
          </div>
          <button
            className="w-[100%] bg-[#8A2320] text-white font-semibold p-[0.5rem] rounded-[2rem]"
            onClick={handleSubmit}
          >
            Send
          </button>
        </div>
        <div className="flex flex-col gap-[1rem] text-lg">
          <div
            className="grid gap-[1rem] items-start"
            style={{ gridTemplateColumns: "1fr 12fr" }}
          >
            <SlLocationPin className="text-[#8A2320] mt-2" />
            <div className="">
              <span className="font-bold">Factory Address :</span>{" "}
              14-18-19-20-21, Industrial area, Banmore Distt. Morena- 476 444
              (M.P.) India, <br />
              <span className="font-bold">Corporate Office :</span> 'IMPERIA' G
              R Group, Naya Bazar, Lashkar, Gwalior, MP, India 474009
            </div>
          </div>
          <div
            className="grid gap-[1rem] items-start"
            style={{ gridTemplateColumns: "1fr 12fr" }}
          >
            <SlPhone className="text-[#8A2320] mt-2" />
            <div className="">
              <a href="tel:[75123 20718]">+91 751 2320718</a> &nbsp;
              <a href="tel:[+91 97525 31000]">+91 97525 31000</a>
            </div>
          </div>
          <div
            className="grid gap-[1rem] items-start"
            style={{ gridTemplateColumns: "1fr 12fr" }}
          >
            <AiOutlineMail className="text-[#8A2320] mt-2" />
            <div className="">
              <a
                href="https://mail.google.com/mail/u/0/#inbox?compose=DmwnWsdLSQmjCPZwblklBtCGMlVwBZGSbVLBnSXSxNGNzCnPPrlzPMmvlGPhxrSWlGCksjHDzFjB"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span>grgroup.sales@gmail.com</span>
              </a>
            </div>
          </div>
          <div>
            {/* <div className='z-0' ref={mapContainer}></div> */}
            <div className="map-box">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3574.5037219836613!2d78.08472909999999!3d26.3749091!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3976b7412ff83a49%3A0xb2cda00296cdd2fc!2sG%20R%20Weavers%20Pvt.%20Ltd.!5e0!3m2!1sen!2sin!4v1685942889474!5m2!1sen!2sin"
                width="auto"
                height="200"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactMid;

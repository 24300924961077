import React from "react";
import "./Product.css";
import { Link } from "react-router-dom";

const ProductCard = ({ title, desecration, img }) => {
  return (
    <>
      <Link to="/products" target="_blank" rel="noreferrer">
        <div className="flex flex-col gap-[1rem] mx-[auto] overflow-hidden h-[fit-content] max-w-[450px] bg-[#F7EAD7] rounded-[1rem] ">
          <img src={img} alt="card-img" className="productCardImg h-[636px]" />
          <div className="px-3 py-3 text-lg our-product font-bold text-center">{title}</div>
          {/* <div
            className="px-3 mb-5 productCardlist"
            dangerouslySetInnerHTML={{ __html: desecration }}
          /> */}
          {/* <div
            className="px-3 mb-5 productCardlist"
            dangerouslySetInnerHTML={{ __html: desecration }}
          /> */}
        </div>
      </Link>
    </>
  );
};

export default ProductCard;

import React, { useEffect } from 'react'
import ContactMid from '../Components/contact/ContactMid'
import Top from '../Components/Top/Top'
import bg from '../assets/carouselBg1.jpg';

const Contact = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div>
      <Top link={'Contact'} title={'Contact'} bg={bg} />
      <ContactMid />
    </div>
  )
}

export default Contact

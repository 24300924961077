import React from "react";
import img from "../../assets/about5.png";
import { Grid } from "@mui/material";

const PromoterMain = () => {
  return (
    <>
      <Grid container justifyContent={"center"} alignItems={"center"} columnSpacing={5} rowSpacing={5}>
        <Grid item lg={4}>
          <div className="">
            <img
              src={img}
              className="lg:h-[450px] md:h-[350px] m-auto h-[250px] rounded-xl"
              alt=""
            />
            <p className="text-center font-bold text-xl mt-5 text-[#8A2320]">Late Shree Girraj Kishore Goyal</p>
          </div>
        </Grid>
        <Grid item lg={8}>
          <div
            className={`text-white bg-[#8A2320] min-h-[300px] sm:min-h-[500px] w-[100%] min-w-[280px] p-[3rem] px-[3rem] md:px-[8rem] lg:px-[10rem] `}
          >
            <div className={`text-lg text-justify mb-[2rem]`}>
              <span className="font-bold">Late Shree Girraj Kishore Goyal,</span> was
              the leading promoter of G.R. Group of Industries, Gwalior. He
              after completing the education, actively showed interest in the
              running of family businesses, having been the top dealer of M/s
              Jiyajee Rao Cotton Mills, Gwalior.
            </div>
            <div className="text-lg text-justify mb-[2rem]">
              In 1990, He had commissioned 3 Star based facilities --Hotel in
              Gwalior, in the name of Hotel Surbhi with active participation of
              his brother Late Shri. Raman Kishore Goyal.
            </div>
            <div className="text-lg text-justify mb-[2rem]">
              <span className="font-bold">C&F of Vadilal Ice cream</span> was
              also obtained and the said businesses are running satisfactorily
              as on date. He in the backdrop of unsatiated business desires and
              with basic ambitious business acumen skills and unstinted efforts
              in building successful industrial units, navigated into different
              industrial branches in the couple of years thereafter.
            </div>
          </div>
        </Grid>
      </Grid>
      <div className={`mb-[2rem] mt-3 relative flex flex-col`}>
        {/* <div className="flex justify-end">
          <div
            className={`relative text-white bg-[#8A2320] min-h-[300px] sm:min-h-[500px] w-[100%] sm:w-[65%] min-w-[280px] p-[3rem] px-[3rem] md:px-[8rem] lg:px-[10rem] `}
          >
            <div className={`text-lg mb-[2rem]`}>
              <span className="font-bold">Late Girraj Kishore Goyal,</span> was
              the leading promoter of G.R. Group of Industries, Gwalior. He
              after completing the education, actively showed interest in the
              running of family businesses, having been the top dealer of M/s
              Jiyajee Rao Cotton Mills, Gwalior.
            </div>
            <div className="text-lg mb-[2rem]">
              In 1990, He had commissioned 3 Star based facilities --Hotel in
              Gwalior, in the name of Hotel Surbhi with active participation of
              his brother Late Shri. Raman Kishore Goyal.
            </div>
            <div className="text-lg mb-[2rem]">
              <span className="font-bold">C&F of Vadilal Ice cream</span> was
              also obtained and the said businesses are running satisfactorily
              as on date. He in the backdrop of unsatiated business desires and
              with basic ambitious business acumen skills and unstinted efforts
              in building successful industrial units, navigated into different
              industrial branches in the couple of years thereafter.
            </div>
            <div className={` classrelative`}>
              <img
                src={img}
                className="lg:h-[450px] md:h-[350px] h-[250px] rounded-xl"
                alt=""
              />
            </div>
          </div>
        </div> */}
        <div className="flex justify-end">
          <div className="text-lg mb-[1rem] text-justify w-[100%] sm:w-[65%] min-w-[280px] pt-[3rem] px-[3rem] md:px-[8rem] lg:px-[10rem]">
            In 1987, G.R. Fabrics Private Limited, Gwalior, (an associate
            concern of G.R. Group of Industries) took its birth in association
            with his brother. The unit is engaged in the manufacture of all
            blends of Suiting, Shirting, Dress materials & bed sheets. The
            market for the products are wide open and Paramilitary Forces play a
            lion’s share in the composition of customers for the company.
          </div>
        </div>
        <div className="flex justify-start">
          <div className="text-lg mb-[2rem] text-justify w-[100%] sm:w-[65%] min-w-[280px] pt-[3rem] px-[3rem] md:px-[8rem] lg:px-[10rem]">
            In 2013, Late Shree Girraj Kishore Goyal, established another milestone
            with the addition a unit under the belt of G.R. Group of Industries
            in the name and style of M/s G.R. Weavers Private Limited, Gwalior.
            The unit would be engaged in the manufacture of Polypropylene woven
            sacks. Late Shree Girraj Kishore Goyal was supported by his sons in
            managerial administration and monitoring balanced production
            schedules, apart from the Senior Managers in their respective
            operational fields in the company.
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoterMain;

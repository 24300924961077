import React from 'react'
import { HiArrowLongRight } from 'react-icons/hi2';
import img from '../../assets/about2.JPG';
import { TbPointFilled } from 'react-icons/tb';

const SubBranch = () => {
    const list = [
        'G.R. Fabrics PVT. LTD. established in 1987',
        'G.R. Weavers PVT. LTD. established in 2013',
        'G.R. Enterprises',
        'Hotel Surbhi, established in 1990',
        'C&F of Vadilal Ice cream, Gwalior region'
    ]
    return (
        <div className='my-[3rem] lg:pl-[8rem] md:pl-[4rem] pl-[2rem]'>
            <div className='text-4xl font-semibold my-[2rem] text-[#8a2320]'>Sub Branches of <span className='font-bold'>G.R. Group of Industries</span></div>
            <div className='relative flex flex-wrap-reverse md:flex-nowrap justify-end items-center my-[3rem]'>
                <img src={img} className='lg:w-[550px] md:w-[400px] w-[100%] rounded-lg' alt='' />
                <div className='sm:w-[100%] lg:w-[50%] bg-[#8A2320] text-white p-8 flex flex-col gap-[2rem] text-lg'>
                    {
                        list.map((l, index) =>
                            <div className='flex gap-[1rem] items-center' key={index}><TbPointFilled className='text-xl' />{l}</div>
                        )
                    }
                </div>
            </div>
        </div>
    )
}

export default SubBranch

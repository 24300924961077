import React from "react";
import { HiArrowLongRight } from "react-icons/hi2";
import img1 from "../../assets/about-img.JPG";
import img2 from "../../assets/about-img3.JPG";

const MainBottom = () => {
  return (
    <div className="bg-[#8A2320] relative text-white lg:py-[7rem] lg:px-[5rem] py-[5rem] sm:px-[2rem] px-0 grid md:grid-cols-2 grid-cols-1 gap-[1.5rem] mb-[5rem]">
      <div className="flex-col flex gap-[1rem] sm:p-[2rem] md:p-[0rem] p-[1rem]">
        <div className="text-2xl font-semibold">
          G.R. Weavers PVT. LTD. <br /> An ISO - 9001:2015 Certified Company
          <div className="flex">
            <img
              className="lg:h-[300px] d-inline mr-4 md:h-[300px] h-[140px] rounded-xl mt-5"
              src={img1}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="lg:p-[4rem] sm:p-[2rem] p-[1rem]">
        <div className="text-2xl text-justify">
          An emerging and vibrant PP/HDPE Sacks Manufacturing company falling
          under the umbrella of G.R. Group of Industries, Gwalior creating
          additional job opportunities to the skilled and unskilled persons from
          the most backward region of Madhya Pradesh.
        </div>
      </div>
      {/* <div className='absolute bottom-[-14rem] w-full flex justify-center'>
                <iframe width="680" height="315" src="https://www.youtube.com/embed/SMnrszAhZuY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div> */}
    </div>
  );
};

export default MainBottom;

import React from 'react';
import { HiArrowLongDown } from 'react-icons/hi2'
import { Link, NavLink } from 'react-router-dom';

const Top = ({title, bg, link, id}) => {
  return (
    <div className='text-white h-[80vh] flex justify-center items-center' style={{ backgroundBlendMode: 'overlay', background: `url(${bg}), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className='max-w-4xl flex flex-col justify-center items-center gap-6 text-lg'>
        <div className='Lg:text-6xl md:text-4xl text-3xl font-semibold text-center'>
          {title}
        </div>
        <div className='font-semibold text-center text-lg'>
          <NavLink to={`/`}>Home</NavLink> {'>'} <NavLink to={'/products'}>{link}</NavLink>
        </div>
        <div>
          <HiArrowLongDown className='text-4xl md:text-5xl border-2 rounded-full p-2 cursor-pointer' />
        </div>
      </div>
    </div>
  )
}

export default Top

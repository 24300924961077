import React from "react";
import { HiArrowLongRight } from "react-icons/hi2";
import Promoter from "./Promoter";
import "./briefPromoters.css";
import img1 from "../../assets/brajesh3.jpg";
import img2 from "../../assets/about4.png";
import PromoterMain from "./PromoterMain";
import { Grid } from "@mui/material";
import './briefPromoters.css'

const BriefPromoters = () => {
  const data = [
    {
      image: img1,
      boldData: `Mr. Brajesh Goyal , Director,`,
      data: ` after completion of
        education, joined the bandwagon of his father Late Shree Girraj Kishore Goyal, and showed keen interest in 
        the promotion of family business in different fields. His area of interests include 
        adherence of production schedules in commensurate with the managerial drawing 
        board figures, cost control, planned procurement of raw materials etc. He 
        shadowed by his father Late Shree Girraj Kishore Goyal, is possessed with qualities in speedy business 
        decision makings as well as entering into arrangements with company 
        stakeholders in the best possible terms and conditions for the company and 
        engaged in application of cornucopia of measures to scale up the business 
        operations of the company on a large scale in sterling years ahead.
        `,
    },
    {
      image: img2,
      boldData: `Mr. Krishna Kumar Goyal, Director,`,
      data: ` designated as 
            Chief Financial Officer, graduated in B.E. Civil Engineering 
           is an approved valuer & Chartered Engineer (Masters in 
           Valuation). To lend additional hand in the promotion of
           family business interests/growth of G.R. Group of Industries.
           He joined with active participation in looking after requirements
           relating to, day to day business operations that include both 
           production /marketing and his untiring attitude in accomplishment
           of business objectives in a time bound schedule, would assist the
           company in the long run. To add, he with able assistance from
           other managerial personnel, would look after the banking 
           transaction of the company for its timely execution and ensure
           that operational fund requirements are properly attended to, for 
           the company.`,
    },
  ];
  console.log(data[0].data);
  return (
    <div className="my-[3rem]">
      <div className=" lg:pl-[8rem] md:pl-[4rem] pl-[2rem]">
        <div className="text-4xl font-semibold my-[2rem] text-[#8a2320]">
          Brief Particulars of the Promoters:
        </div>
      </div>
      <PromoterMain />
      <Grid
        className="sm:mb-[0] lg:mb-[10rem] img-flex-col"
        container
        justifyContent={"center"}
        columnSpacing={5}
        rowSpacing={5}
        alignItems={"center"}
      >
        <Grid item md={7} lg={8}>
          <div
            className={`text-white bg-[#8A2320] min-h-[300px] sm:min-h-[500px] w-[100%] min-w-[280px] p-[3rem] px-[3rem] md:px-[2rem] `}
          >
            <div className="section-set text-lg text-justify mb-[2rem]">
              <span className="font-bold ">{data[0].boldData}</span>
              {data[0].data}
            </div>
          </div>
        </Grid>
        <Grid item md={5} lg={4}>
          <img
            src={data[0].image}
            className="lg:h-[450px] m-auto md:h-[350px] h-[250px] rounded-xl"
            alt=""
          />
          <p className="text-center font-bold text-[#8A2320] text-xl mt-5">Mr. Brajesh Goyal</p>
        </Grid>
      </Grid>
      <Grid
        container
        justifyContent={"center"}
        columnSpacing={5}
        rowSpacing={5}
        alignItems={"center"}
      >
        <Grid item md={5} lg={4}>
          <img
            src={data[1].image}
            className="lg:h-[450px] md:h-[350px] m-auto h-[250px] rounded-xl"
            alt=""
          />
          <p className="text-center font-bold text-[#8A2320] text-xl mt-5">Mr. Krishna Kumar Goyal</p>
        </Grid>
        <Grid item md={7} lg={8}>
          <div
            className={`text-white bg-[#8A2320] min-h-[300px] sm:min-h-[500px] w-[100%] min-w-[280px] p-[3rem] px-[3rem] md:px-[2rem] `}
          >
            <div className="text-lg director-text text-justify mb-[2rem]">
              <span className="font-bold">{data[1].boldData}</span>
              {data[1].data}
              <br />
              <br />
              <br />
            </div>
          </div>
        </Grid>
      </Grid>
      {/*   <Promoter reverse={false} data={data[0]} />
      <Promoter reverse={true} data={data[1]} /> */}
    </div>
  );
};

export default BriefPromoters;

import React, { useEffect, useState } from "react";
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import Slider from "react-slick";
import img1 from "../../assets/productCard1.png";
import img2 from "../../assets/productCard2.png";
import img3 from "../../assets/productCard3.png";
import img4 from "../../assets/productCard4.png";
import img5 from "../../assets/productCard5.png";
import ProductCard from "./ProductCard";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <>
      <div className="rightArrow">
        <HiArrowLongRight
          style={{
            display: "block",
          }}
          onClick={onClick}
          className="text-[3rem] border-2 rounded-full p-2 cursor-pointer mx-3"
        />
      </div>
    </>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <>
      <div className="leftArrow">
        <HiArrowLongLeft
          className="text-[3rem] border-2 rounded-full p-2 cursor-pointer mx-3"
          style={{
            display: "block",
          }}
          onClick={onClick}
        />
      </div>
    </>
  );
}

const Products = () => {
  var settings = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          arrows: false,
          dots: true,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };
  const [data, setData] = useState([]);

  const baseUrl = "https://gr8-back.onrender.com";

  const getData = async () => {
    const response = await fetch(`${baseUrl}/product/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
    });
    if (response.status === 401) {
      console.log("error");
    }
    const data = await response.json();
    // console.log(data);
    setData(data?.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="flex flex-col gap-[2rem] justify-center items-center">
        <div className="text-3xl font-semibold our-product-section">
          Our Products
        </div>
        <div className="max-w-[766px] p-[1rem] text-center">
          An upcoming leader in manufacturing of PP/HDPE, BOPP bags used for
          cement, rice, flour, fertilizers, and other agro products packaging.
          For bulk and small packaging.
        </div>
      </div>
      <Slider {...settings} style={{ paddingBottom: "40px" }}>
     {/*  <div>
          <ProductCard
            img={img2}
            title="BOPP Metalised"
            desecration="<p>This is a Premium Product which gives rich shining that makes your product look expensive and of high quality !</p><p>It has a Three layer of film containing,</p><ul><li>one BOPP Glossy/ Matte</li><li>one Metalised BOPP</li><li>and it is laminated on the PP bag by extrusion coating.</li></ul><p>Size: 5kg to 50 kg Available</p>"
          />
        </div>
        <div>
          <ProductCard
            img={img3}
            title="BOPP Both Sides"
            desecration="<p>This is our Premium Product. This type of Packaging will give a rich looking finish which will give your product, a high quality finish.</p><p>Types: It is available in Both Glossy and Matte finish on both sides or single side.</p><p>Used For:</p><ul><li>Rice</li><li>Flour</li><li>Daal</li><li>Refined Wheat (Maida), etc</li></ul><p>This is a Two layer BOPP consisting of Matte/ Glossy finish laminated to the PP bag using extrusion coating.</p>"
          />
        </div>
        <div>
          <ProductCard
            img={img4}
            title="BOPP Bag with Handles"
            desecration="<p>Types:</p><ul><li>Glossy</li><li>Matte</li><li>Metalised</li></ul><p>Used for:</p><ul><li>Rice</li><li>Flour</li><li>Daal</li></ul><p>Size: 5kg to 10kg(Small type of Packaging)</p>"
          />
        </div>
        <div>
          <ProductCard
            img={img5}
            title="Laminated PP Bag"
            desecration="<p>This product is mainly used in exporting of Rice, pulses, etc.</p><ul><li>Natural Bag</li><li>Product for Export</li><li>Used for Outer Packaging. (Packaging of small Packets)</li><p>FLEXOGRAPHIC PRINTING IS USED HERE.</p><p>Type:</p><ul><li>Laminated on both the sides</li><li>Laminated on a single side.</li></ul><p>Colors: 6 colors available.</p>"
          />
        </div> */}
      {/*   <div>
          <ProductCard
            img={img1}
            title="Micem Cement"
            desecration="<p>This product is mainly used in exporting of Rice, pulses, etc.</p><ul><li>Natural Bag</li><li>Product for Export</li><li>Used for Outer Packaging. (Packaging of small Packets)</li><p>FLEXOGRAPHIC PRINTING IS USED HERE.</p><p>Type:</p><ul><li>Laminated on both the sides</li><li>Laminated on a single side.</li></ul><p>Colors: 6 colors available.</p>"
          />
        </div> */}
          {data.map((element, index) => {
            return (
              <>
              <div key={index}>
                <ProductCard
                  img={element.images[0]}
                  title={element.name}
                  desecration="<ul><li> If You want something reliable and rigid, this is
            it.</li><li>These Bags Are used mainly for heavy material.</li><li>The Products which are used in Bulk Quantities</li><li>Used for:<br/>
             Cement, Fertilizers, Fodder and other products.</li></ul>"
                />
        </div>
              </>
            );
          })}
      </Slider>
      <div className="flex justify-center flex-row"></div>
    </>
  );
};

export default Products;

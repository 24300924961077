import React from 'react';
import img1 from '../../assets/license1.png';
import img2 from '../../assets/license2.png';
import img3 from '../../assets/license3.png';
import img4 from '../../assets/certificate.png';

const Certificates = () => {
    return (
        <div className='my-[3rem] bg-[var(--primary)] px-[4rem] sm:px-[1rem] py-[5rem] text-white text-xl flex flex-col gap-[3rem]'>
            <div className='flex flex-col sm:flex-row gap-[3rem] justify-center text-center'>
                <div className=''>
                    <img src={img1} alt='' className='mx-auto' />
                    <div className='my-2'>BIS License</div>
                </div>
                <div className=''>
                    <img src={img2} alt='' className='mx-auto' />
                    <div className='my-2'>BIS License</div>
                </div>
            </div>
            <div className='flex flex-col sm:flex-row gap-[3rem] justify-center text-center'>
                <div className=''>
                    <img src={img3} alt='' className='mx-auto' />
                    <div className='my-2'>GRW Factory Act Licence </div>
                </div>
                <div className=''>
                    <img src={img4} alt='' className='mx-auto' />
                    <div className='my-2'>ISO Certificate </div>
                </div>
            </div>
        </div>
    )
}

export default Certificates

import React from "react";
import img1 from "../../assets/Ultratech-Cement.png";
import img2 from "../../assets/Supreme.png";
import img3 from "../../assets/mycem.png";
import img4 from "../../assets/nuvoco.png";
import img5 from "../../assets/Prism.png";
import Slider from "react-slick";

const Partners = () => {
  var settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 1000,
    autoplay: false,
    autoplaySpeed: 1000,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };
  return (
    <>
      <div className="text-3xl font-bold text-[#8a2320]" style={{ textAlign: "center" }}>
        Our Partners
      </div>
      <div  className="mx-8 mt-[120px] sm:mx-10 md:mx-10 lg:mx-20 ">
        <Slider {...settings}>
          <div>
            <img src={img1}  className="px-5 w-[250px] mx-auto" alt="" />
          </div>
          <div>
            <img src={img2} className="px-5 w-[250px] mx-auto" alt="" />
          </div>
          <div>
            <img src={img3} className="px-5 w-[250px] mx-auto" alt="" />
          </div>
          <div>
            <img src={img4} className="px-5 w-[250px] mx-auto" alt="" />
          </div>
          <div>
            <img src={img5} className="px-5 w-[250px] mx-auto" alt="" />
          </div>
        </Slider>
      </div>
    </>
  );
};

export default Partners;

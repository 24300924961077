import React from "react";
import { HiArrowLongRight } from "react-icons/hi2";
import img from "../../assets/Kishore-Goyal.jpg";
import { Grid } from "@mui/material";

const Founders = () => {
  return (
        <div className="mt-[3rem] bg-[#8A2320] text-white mb-[15rem] relative py-[4rem] flex justify-between gap-[1rem] md:px-[4rem] px-[2rem]">
    <Grid container>
        <Grid item xs={12} lg={3}>
          <div className="text-4xl font-semibold p-4">Founder</div>
        </Grid>
        <Grid item xs={12} lg={9}>
          <div className="max-w-[900px] text-justify p-4 ">
            <span className="font-semibold">
              Late Shri Girraj Kishore Goyal founded
            </span>{" "}
            the group. He started the business in 1950's along with his father
            under the name of{" "}
            <span className="font-semibold">M/s. Chedilal Phoolchand</span>. He
            continued with him and got the agency of Grasim and Raymond's during
            1980's. In 1996 he started a cloth Processing unit in Bamor region of
            Morena District.
            {/* In 1996 * instead of 1980s
And cloth manufacturing instead of yarn manufacturing */}
          </div>
        </Grid>
      <Grid xs={12} lg={3}>
        <img
          src={img}
          alt=""
          className="rounded-lg h-[400px] object-cover m-auto lg:w-[300px] sm:w-[250px] w-[100%] md:w-[400px]"
        />
        <p className="text-center font-bold text-xl mt-5 text-[#FFFFFF]">Late Shree Girraj Kishore Goyal</p>
      </Grid>
      <Grid xs={12} lg={9}>
        <div className="flex flex-col gap-[2rem] p-5">
          <div className="">
            In 1996, he started a unit in Bamor district G.R. Fabrics cloth
            Processing Unit.
          </div>
          <div className="">
            In 2013, he established G.R. Weavers Pvt. Ltd. First PP HDPE
            Manufacturing Plant, Gwalior Chambal region.
          </div>
          <div className="">
            He's a founder of{" "}
            <span className="font-semibold">G.R. GROUP OF INDUSTRIES</span>
          </div>
        </div>
      </Grid>
      </Grid>
      {/* <div className='flex flex-col gap-[2rem]'>
                <div className='text-4xl font-semibold'>Directors</div>
            </div>
            <div className='max-w-[900px] p-4 '>
                <span className='font-semibold'>Late Shree Girraj Kishore Goyal founded</span> the group. He started the business in 1950's along with his father under the name of <span className='font-semibold'>M/s. Chedilal Phoolhand</span>. He continued with him and got the agency of Grasim and Raymond's during 1980's.
            </div> */}
      <div className="lg:bottom-[-8rem] md:bottom-[-2rem] bottom-[-12rem] flex-wrap-reverse flex gap-[1rem]">
        <Grid container>
        </Grid>
      </div>
    </div>
  );
};

export default Founders;

import React from "react";
import BriefElement from "./BriefElement";
import { Grid } from "@mui/material";

const CompanyBrief = () => {
  return (
    <div className="align-center-companyBrief">
      <Grid container>
        <Grid item xs={12} sm={6} lg={6}>
          {/* <BriefElement
            name={"Plant with capacity of 4400 Metric/Annum"}
            number={"1"}
          /> */}
           <BriefElement name={"Stitching Machines"} number={"25"} />
          <BriefElement name={"Looms"} number={"53"} />
          <BriefElement name={"Bag Conversion Machine (BCS)"} number={"1"} />
          <BriefElement name={"Rotogravure Printing Machine"} number={"1"} />

        </Grid>
        <Grid item xs={12} sm={6} lg={6}>
        <BriefElement name={"Online Printing"} number={"2"} />
          <BriefElement
            name={"Multifilament Yarn Lofil Machine"}
            number={"1"}
          />
          <BriefElement name={"Tandem Lamination Plant"} number={"1"} />
          <BriefElement name={"Gusseting Machine"} number={"1"} />
          <BriefElement name={"Tape Stretching Line"} number={"1"} />
          
        </Grid>
      </Grid>
    </div>
  );
};

export default CompanyBrief;

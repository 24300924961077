import React from "react";
import { HiArrowLongRight } from "react-icons/hi2";
import img from "../../assets/about1.JPG";
import { Grid } from "@mui/material";
import "./company.css";

const Company = () => {
  return (
    <>
      <div className="the-company">
        <Grid container>
          <Grid item xs={12} sm={12} md={6} lg={5}>
            <div
              className={`company-title text-5xl font-semibold mb-[2rem] flex text-[#8a2320]`}
            >
              The Company
              <div className="company-text">
                <div className="flex flex-col text-justify justify-center text-lg text-black gap-[2rem] max-w-[400px] mb-16">
                  In early 2000's we started Uniform supplies in Paramilitary
                  Forces like, Border Security Force (BSF), Central Reserve
                  Police Force (CRPF), Indo Tibetan Border Police (ITBP).
                </div>
                <div className="flex flex-col text-justify justify-center text-lg text-black gap-[2rem] max-w-[400px]">
                  During 2014, we started the first unit of PP/HDPE Bag
                  Manufacturing in Gwalior, Chambal region.
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={7}>
            <div className="the-company-content text-justify text-white bg-[#8A2320]  p-[4rem] flex flex-col gap-[2rem]">
              <div>
                In early 1950's, under the name of M/s. Chedilal Phoolchand
                we began trading. In 60’s we were the top dealer of M/s
                Jiyajee Rao Cotton Mills, Gwalior. During 1980's we got the
                agency for Grasim & Raymond. In 1990's we entered the Clothe
                Manufacturing market under the name of G.R. Fabrics PVT. LTD.
              </div>
              <div className="text-xl">Specific Years: 1987</div>
              <div className="company-img">
                <img
                  src={img}
                  className="lg:h-[400px] md:h-[300px] h-[140px] rounded-xl"
                  alt=""
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      {/* <div className={`mb-[18rem] relative flex flex-wrap`}>
        <div
          className={`min-h-[300px] sm:min-h-[500px] w-[100%] sm:w-[45%] min-w-[280px] p-[3rem] pl-[5rem]`}
        >
        </div>
        <div className="text-white bg-[#8A2320] sm:w-[55%] w-[100%] min-h-[300px] sm:min-h-[500px] min-w-[300px] p-[4rem] flex flex-col gap-[2rem]">
          <div className="">
            In the early 1950's, under the name of M/s. Chedilal Phoolhand we
            began trading. During 1980's we got the agency for Grasim & Raymond.
            In 1990's we entered the Clothe Processing market under the name of
            G.R. Fabrics PVT. LTD.
          </div>
          <div className="text-xl">Specific Years: 1987</div>
        </div>
        <div className="absolute w-[100%] flex gap-[2rem] justify-center bottom-[-10rem]">
          <div className="flex flex-col justify-center text-lg text-black gap-[2rem] max-w-[400px]">
            <div className="">
              In early 2000's we started Clothing supply in Paramilitary Forces
              like, Border Security Force (BSF), Central Reserve Police Force
              (CRPF), Indo Tibetan Border Police (ITBP).
            </div>
            <div className="">
              During 2014, we started the first unit of PP/HDPE Bag
              Manufacturing in Gwalior, Chambal region.
            </div>
          </div>
         
        </div>
      </div> */}
    </>
  );
};

export default Company;

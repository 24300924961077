import React, { useEffect } from 'react'
import CareerMid from '../Components/career/CareerMid'
import Top from '../Components/Top/Top'
import bg from '../assets/CarouselBg4.jpg';

const Career = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const id = "career"
  return (
    <div>
      <Top title={'Career'} link={'Career'} id={id} bg={bg} />
      <CareerMid id={id} />
    </div>
  )
}

export default Career

import React from "react";
import Slider from "react-slick";
import '../Products/Product.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Product = ({ reverse, data }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
  };

  return (
    <>
      <section>
        <div
          className={`mb-[20rem] relative flex ${
            reverse && "flex-row-reverse"
          } flex-wrap`}
        >
          <div
            className={`text-white bg-[#8A2320] min-h-[300px] sm:min-h-[500px] w-[100%] sm:w-[45%] min-w-[280px] p-[3rem] ${
              reverse ? "pr-[rem]" : "pl-[5rem]"
            }`}
          >
            <div
              className={`text-4xl font-bold mb-[2rem] flex ${
                reverse && "justify-end"
              }`}
            >
              {data?.name}
            </div>
          </div>
          <div className="sm:w-[55%] w-[100%] min-h-[300px] sm:min-h-[500px] min-w-[300px] p-[4rem]">
            {data?.description}
          </div>
          <div className="absolute w-[100%] product-image flex justify-center bottom-[-10rem]">
            {data?.images?.map((d, index) => (
              <img
                src={d}
                className="lg:h-[450px] rounded-[1rem] md:h-[350px] h-[250px] "
                alt=""
              />
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Product;

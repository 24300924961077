import React, { useEffect, useState } from "react";
import CareerCard from "./CareerCard";
import { Grid } from "@mui/material";

const CareerMid = ({ id }) => {
  const [data, setData] = useState([]);

  const baseUrl = "https://gr8-back.onrender.com";

  const getData = async () => {
    const response = await fetch(`${baseUrl}/job/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
    });
    if (response.status === 401) {
      console.log("error");
    }
    const data = await response.json();
    setData(data?.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const card = data?.map((d, index) => <CareerCard data={d} key={index} />);
  return (
    <>
      <section id={id}>
        <div className="my-10 md:px-[4rem] sm:px-[2rem] px-[1rem]">
          <div className="text-4xl font-semibold mb-10 text-[#8a2320]">At this time, we don't have any vacancies.</div>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={6}>
              {/* {card} */}
            </Grid>
          </Grid>
          {/* <CareerCard title={'Production Engineer'} />
                <CareerCard title={'Quality Officer'} />
                <CareerCard title={'Production Engineer'} /> */}
        </div>
      </section>
    </>
  );
};

export default CareerMid;

import React, { useEffect, useState } from 'react'
import Top from '../Components/Top/Top'
import bg from '../assets/bgImage1.JPG';
import Company from '../Components/about/Company';
import CompanyBrief from '../Components/about/CompanyBrief';
import Founders from '../Components/about/Founders';
// import Directors from '../Components/about/Directors';
import SubBranch from '../Components/about/SubBranch';
import Certificates from '../Components/about/Certificates';
import BriefPromoters from '../Components/about/BriefPromoters';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      <Top title={'About Us'} bg={bg} link={'About'} />
      <Company />
      <CompanyBrief />
      <Founders />
      {/* <Directors /> */}
      <SubBranch />
      <BriefPromoters />
      <Certificates />
    </div>
  )
}

export default About

// import React from 'react';
import bg from '../../assets/homeslider1.JPG';
import bg1 from '../../assets/homeslider2.JPG';
import bg2 from '../../assets/homeslider3.JPG';
// import bg3 from '../../assets/homeslider4.JPG';
import bg4 from '../../assets/homeslider5.JPG';
import { Link } from 'react-router-dom';
import { HiArrowLongLeft, HiArrowLongRight } from 'react-icons/hi2';

// const MainTop = () => {
//   return (
// <div className='text-white h-[100vh] flex justify-center items-center' style={{ background: `url(${bg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPosition:'center' }}>
//   <div>
//     {/* <HiArrowLongLeft className='text-[3rem] border-2 rounded-full p-2 cursor-pointer' /> */}
//   </div>
//   <div className='max-w-4xl flex flex-col justify-center items-center gap-6 text-lg'>
//     <div className='Lg:text-6xl md:text-4xl text-3xl font-semibold text-center'>
//       One of the Largest and manufacture and exporters
//     </div>
//     <div className='font-semibold text-center text-lg'>
//     An upcoming leader in manufacturing of PP/HDPE, BOPP bags used for cement, rice, flour, fertilizers, and other agro products packaging. For bulk and small packaging.
//     </div>
//     <div className='border-[#8A2320] border-2 p-2 rounded-[5rem]'>
//       <button className='flex items-center gap-2 font-semibold bg-[#8A2320] px-4 py-2 rounded-3xl'><span><Link to="/about">Know more</Link></span><HiArrowLongRight className='text-[1.5rem]' /></button>
//     </div>
//   </div>
//   <div>
//     {/* <HiArrowLongRight className='text-[3rem] border-2 rounded-full p-2 cursor-pointer' /> */}
//   </div>
// </div>
//   )
// }

// export default MainTop

import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { onClick } = props;
  return (
    <>
      <div className="absolute top-[50%] right-[0.5rem]">
        <HiArrowLongRight
          style={{
            display: "block",
          }}
          onClick={onClick}
          className="text-[3rem] border-2 text-white hover:border-[#8A2320] hover:text-[#8A2320] rounded-full p-2 cursor-pointer mx-3"
        />
      </div>
    </>
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
    <>
      <div className="absolute z-20 left-[0.5rem] top-[50%]">
        <HiArrowLongLeft
          style={{
            display: "block",
          }}
          onClick={onClick}
          className="text-[3rem] border-2 text-white hover:text-[#8A2320] hover:border-[#8A2320] rounded-full p-2 cursor-pointer mx-3"
        />
      </div>
    </>
  );
}

const Carousel = () => {
  const bgs = [bg, bg1, bg2, /* bg3, */ bg4,];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };

  return (
    <div style={{overflow:'hidden'}}>
    <Slider {...settings} className='flex items-center'>
      {
        bgs.map((bg, index) =>
          <div key={index}>
            {/* <img src={bg} alt="Slide 1" className='absolute' style={{width:'100%', height:'100%'}} /> */}
            <div className='text-white h-[100vh] flex justify-center items-center hero-bg' style={{ backgroundBlendMode: 'overlay', background: `url(${bg}), linear-gradient(rgba(0,0,0,0.5),rgba(0,0,0,0.5))`, backgroundRepeat: 'no-repeat !important', backgroundSize: 'cover', backgroundPosition: 'center' }}>
              <div className="overlay"></div>
              <div>
                {/* <HiArrowLongLeft className='text-[3rem] border-2 rounded-full p-2 cursor-pointer' /> */}
              </div>
              <div className='max-w-4xl flex flex-col justify-center items-center gap-6 text-lg'>
                <div className='Lg:text-6xl md:text-4xl text-3xl font-semibold text-center px-[3rem]'>
                One of the largest Manufacturers and Exporters
                </div>
                <div className='font-semibold text-center text-lg px-[3rem]'>
                  An upcoming leader in manufacturing of PP/HDPE, BOPP bags used for cement, rice, flour, fertilizers, and other agro products packaging. For bulk and small packaging.
                </div>
                <div className='border-[#8A2320] border-2 p-2 rounded-[5rem]'>
                  <button className='flex items-center gap-2 font-semibold bg-[#8A2320] px-4 py-2 rounded-3xl'><span><Link to="/about">Know more</Link></span><HiArrowLongRight className='text-[1.5rem]' /></button>
                </div>
              </div>
              <div>
                {/* <HiArrowLongRight className='text-[3rem] border-2 rounded-full p-2 cursor-pointer' /> */}
              </div>
            </div>
          </div>
        )
      }
    </Slider>
    </div>
  );
};

export default Carousel;


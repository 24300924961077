import React, { useEffect, useState } from 'react'
import BrandBrief from '../Components/ExtraHome/BrandBrief'
import MainBottom from '../Components/Main/MainBottom'
import MainTop from '../Components/Main/MainTop'
import Products from '../Components/Products/Products'
// import Testimonials from '../Components/HomeBottom/Testimonials'
import Partners from '../Components/HomeBottom/Partners'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
 
  return (
    <div>
      <MainTop />
      <MainBottom />
      <Products />
      <BrandBrief />
      {/* <Testimonials /> */}
      <Partners />
    </div>
  )
}

export default Home

import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/logo.png";
import $ from "jquery";

function Navbar() {
  const path = useLocation().pathname;
  const links = [
    ["Home", "/"],
    ["About", "/about"],
    ["Products", "/products"],
    ["Career", "/career"],
    ["Enquiry", "/enquiry"],
    ["Contact", "/contact"],
  ];

  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };
  function handleOnClick() {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
    setIsNavOpen(false)
    // setIsLoading(false);
  }
  useEffect(() => {
    handleOnClick();
  }, []);

  const [backgroundColor, setBackgroundColor] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPosition = window.pageYOffset;
      if (currentScrollPosition > 0) {
        setBackgroundColor(true);
      } else {
        setBackgroundColor(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    $(window).on("scroll", function () {
      if ($(window).scrollTop() > 50) {
        $(".logo").css("height", "60px");
      } else {
        //remove the background property so it comes transparent again (defined in your css)
        $(".logo").css("height", "148px");
      }
    });
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="bg-white-logo">
          <div
            className="loader-logo"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%",
            }}
          ></div>
        </div>
      ) : (
        <>
          <nav
            className={`flex items-center z-10 justify-between flex-wrap px-6 fixed top-0 w-screen ${
              (isNavOpen || backgroundColor) &&
              "bg-[rgba(0,0,0,0.4)] backdrop-blur-sm"
            }`}
          >
            <div className="flex items-center flex-shrink-0 text-white mr-6">
              <img src={logo} className="h-14 logo" alt="logo" />
            </div>
            <div className="block lg:hidden">
              <button
                className="flex items-center px-3 py-2 border rounded text-gray-200 border-gray-400 hover:text-white hover:border-white"
                onClick={toggleNav}
              >
                <svg
                  className="fill-current h-3 w-3"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <title>Menu</title>
                  <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                </svg>
              </button>
            </div>
            <div className="w-full block lg:flex lg:items-center lg:w-auto">
              <div className="text-sm lg:flex-grow">
                {links.map((link, index) => (
                  <NavLink
                    onClick={() => handleOnClick()}
                    to={link[1]}
                    key={index}
                    className={`block mt-4 font-bold ${
                      !isNavOpen && "hidden"
                    } text-lg lg:inline-block lg:mt-0 hover:text-[#8A2320] mr-12 ${
                      path === link[1]
                        ? "border-b-4 border-[#8A2320] text-[#8A2320] lg:text-[#8A2320]"
                        : "text-white"
                    }`}
                  >
                    {link[0]}
                  </NavLink>
                ))}
              </div>
            </div>
          </nav>
        </>
      )}
    </>
  );
}

export default Navbar;

import React, { useEffect, useState } from "react";
import "../enquiry/enquiry.css";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";



// let axiosConfig = {
//   headers: {
//     "Content-Type": "application/x-www-form-urlencoded",
//   },
// };

// let axiosRequest = {
//   request: {
//     withCredentials: true,
//   },
// };

const baseUrl = 'https://gr8-back.onrender.com';;
function EnquiryForm() {
  const [productData, setProductData] = useState([]);
  // const [product, setProduct] = useState("");
  const queryData = {
    name: '', description: '', email: '', contact: '', product: ''
  }
  const [data, setData] = useState(queryData);

  // const [name, setName] = useState("");

  const handleChange = (event) => {
    // setProduct(event.target.value);
    setData({ ...data, [event.target.name]: event.target.value })
  };
  const inputStyle = {
    color: "#9F9F9F !important",
    fontWeight: 400,
    fontSize: "16px !important",
    marginBottom: "35px",
  };


  const getProducts = async () => {
    const response = await fetch(`${baseUrl}/product/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
    });
    const data = await response.json();
    if (data?.success) {
      setProductData(data?.data);
      console.log(data)
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(data);
    if (data?.name.trim() === '' || data?.description.trim() === '' || data?.email.trim() === '') {
      window.alert('Please fill all the fields');
    }
    else if (data?.contact.length !== 10) {
      window.alert('Please enter a valid phone number');
    }
    else {
      const response = await fetch(`${baseUrl}/enquiry/create`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
      });
      const res = await response.json();
      if (res?.success) {
        window.alert('Successfully done')
      }
      else {
        window.alert('Something went wrong')
      }
    }
  }

  return (
    <div>
      <div class="container mx-auto">
        <div className="text-center">
          <form action="javascriptVoid(0)">
            <h2 className="enquiry-text">Enquiry !</h2>
            <div className="enquiry-form">
              <form>
                <FormControl fullWidth className="select-product">
                  <InputLabel id="demo-simple-select-label">
                    Choose a Product
                  </InputLabel>
                  <Select
                    className="select-product"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={data.product}
                    name="product"
                    label="Choose an Product"
                    onChange={handleChange}
                  >
                    {
                      productData?.map((product) =>
                        <MenuItem value={product._id}>{product.name}</MenuItem>
                      )
                    }
                  </Select>
                  <TextField
                    id="outlined-basic"
                    style={inputStyle}
                    label="Name"
                    name="name"
                    type="text"
                    value={data.name}
                    onChange={handleChange}
                    variant="outlined"
                  />
                  <TextField
                    id="outlined-basic"
                    type="email"
                    style={inputStyle}
                    name="email"
                    value={data.email}
                    onChange={handleChange}
                    label="Email Address"
                    variant="outlined"
                  />
                  <TextField
                    id="outlined-basic"
                    type="number"
                    style={inputStyle}
                    name="contact"
                    value={data.contact}
                    onChange={handleChange}
                    label="Phone"
                    variant="outlined"
                  />
                  <div className="input-group" style={{ marginBottom: "40px" }}>
                    <textarea
                      required=""
                      type="text"
                      name="description"
                      value={data.description}
                      onChange={handleChange}
                      style={{ height: "137px", marginBottom: "40px" }}
                      autoComplete="off"
                      className={`input border-2 rounded-md p-[0.5rem] px-2 w-[100%] `}
                    />
                    <label className="user-label">Description</label>
                    <button
                      style={{
                        xl: { marginBottom: "138px" },
                        sm: { marginBottom: "30px" },
                      }}
                      onClick={handleSubmit}
                      className="w-[100%] bg-[#8A2320] text-white font-semibold p-[0.5rem] rounded-[2rem]"
                    >
                      Submit
                    </button>
                  </div>
                </FormControl>
              </form>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default EnquiryForm;

import React, { useState } from 'react'

const CareerCard = ({ data }) => {
    const baseUrl = 'https://gr8-back.onrender.com';

    const contactData = {
        name: '', resume: '',
    }

    const [postData, setPostData] = useState(contactData);

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(postData);
        const formData = new FormData();
        if (postData.name.trim() === '' || postData.resume === '') {
            window.alert('Please fill all the fields');
        }
        else {
            formData.append('name', postData.name);
            formData.append('resume', postData.resume);
            formData.append('job', data?._id);
            console.log(formData)
            const response = await fetch(`${baseUrl}/candidate/create`, {
                method: "POST",
                body: formData,
                redirect: "follow",
            })
            if (response.status === 401) {
                console.log('error');
            }
            else{
                const result= await response.json();
                console.log(result)
                window.alert('Success');
                setPostData(contactData)
            }
        }
    }

    return (
        <div className='rounded-lg max-w-lg-[36rem]' style={{ boxShadow: '2px 10px 28px rgba(138, 35, 32, 0.1)' }}>
            <div className='bg-[#8A2320] text-white rounded-t-lg md:px-6 px-2 py-2'>{data?.title}</div>
            <div className='flex flex-col gap-[2rem] md:px-7 px-2 py-6 pb-8'>
                <div className=''>
                    <span className='font-bold'>Designation:-</span> {data?.position}
                </div>
                <div className=''>
                    {data?.description}
                    Candidates should be Post Graduate / Graduates in any discipline preferably Physics / Chemistry / Mathematics
                </div>
                <div className=''>
                    Candidate should be very young and dynamic in nature with 4-5 years of industrial experience.
                </div>
                    <form className='flex flex-col gap-[1.5rem] items-start px-4' >
                        <lable htmlFor='name'>Your Name</lable>
                        <input type={'text'} name='name' id='name' className='border py-2 rounded-sm w-[100%]' value={postData?.name} onChange={(e) => setPostData({ ...postData, name: e.target.value })} />
                        <lable>upload Resume</lable>
                        <input type={'file'} className='' onChange={(e) => setPostData({ ...postData, resume: e.target.files[0] })} />
                        <button className='bg-[#8A2320] px-5 py-2 rounded-[2rem] text-white send-btn' onClick={handleSubmit}>Send</button>
                    </form>
            </div>
        </div>
    )
}

export default CareerCard

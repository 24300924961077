import React, { useEffect, useState } from 'react'
import Product from '../Components/ProductMain/Product'
import ProductMain from '../Components/ProductMain/ProductMain'
import ViewMore from '../Components/ProductMain/ViewMore'

const Products = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [data, setData] = useState([]);

  const baseUrl = "https://gr8-back.onrender.com";

  const getData = async () => {
    const response = await fetch(`${baseUrl}/product/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
    });
    if (response.status === 401) {
      console.log('error');
    }
    const data = await response.json();
    // console.log(data);
    setData(data?.data);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <ProductMain />
      {
        data?.map((d, index) =>
          <Product reverse={index % 2 === 0 ? false : true} data={d} key={index} />
        )
      }
      {/* <ViewMore /> */}
    </div>
  )
}

export default Products
